import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import SEO from '../components/seo'
import SidebarBlog from '../components/sidebar-blog'

const SingleArticle = ({ data }) => {
  if (!data) return null
  const article = data.prismicNews
  const image = getImage(article.data.immagine_in_evidenza.localFile)

  return (
    <Layout>
      <SEO title={article.data.titolo.text} article={true} image={article.data.immagine_in_evidenza.localFile && (article.data.immagine_in_evidenza.localFile.publicURL)} />
      <section className="px-6 py-12">
        <div className="container mx-auto flex gap-12 flex-wrap">
          <article className="articolo flex-1">
            <header className="mb-8">
              {image ? (
                <GatsbyImage image={image} alt={article.data.titolo.text} className="mb-8 rounded-xl" />
              ) : (
                <StaticImage src="../images/default-post-image.png" alt="default post image" placeholder="blurred" draggable="false" className="mb-8 rounded-xl" />
              )
              }

              <h1 className="text-3xl font-bold mb-2">{article.data.titolo.text}</h1>
              <span className="text-sm text-persian-green-500 block">Scritto da Cascinanet S.r.l.</span>
            </header>
            <div className="entry prose prose-persian-green max-w-none">
              {parse(article.data.contenuto.html)}
            </div>
          </article>

          <aside className="w-full lg:w-4/12">
            <SidebarBlog />
          </aside>
        </div>
      </section>
    </Layout>
  )
}

export default SingleArticle

export const pageQuery = graphql`
  query ArticleBySlug($uid: String!) {
    prismicNews(uid: { eq: $uid }) {
      uid
      data {
        titolo {
          text
        }
        sottotitolo {
          text
        }
        contenuto {
          html
        }
        immagine_in_evidenza {
            localFile {
                childImageSharp {
                    gatsbyImageData (
                        placeholder: BLURRED
                    )
                }
                publicURL
            }
        }
      }
      type
    }
  }
`