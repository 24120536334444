import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const SidebarBlog = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicNews(sort: {fields: last_publication_date, order: DESC} limit:5) {
        nodes {
          uid
          data {
            titolo {
              text
            }
          }
        }
      }
    }
  `)

    return (
        <div className="bg-gray-100 p-6 border-t-8 rounded-bl-3xl border-persian-green-500">
            <span className="block text-persian-green-500 font-bold text-xl mb-4">Ultimi articoli</span>
            <ul className="space-y-2">
            {data.allPrismicNews.nodes.map((item, index) => (
                    <li className="hover:translate-x-2 transition-transform" key={index}><Link to={`/${item.uid}`} className="hover:text-persian-green-600" activeClassName="text-persian-green-500">{item.data.titolo.text}</Link></li>
                ))}
            </ul>
            <Link to="/news" className="text-gray-500 font-bold text-sm block mt-6 hover:text-persian-green-500">Leggi tutti gli articoli</Link>
        </div>
    )
}

export default SidebarBlog